<template>
    <v-layout column fill-height>
        <bread-crumbs />
        <div class="ma-1 pa-1 fill-height">
            <div class="title-bar">
                <h3 v-if="subHeading" class="justify-start">{{ title }}</h3>
                <h1 v-else class="justify-start">{{ title }}</h1>
                <v-spacer />
                <router-view name="actions" />
                <slot name="actions" />
            </div>
            <slot />
        </div>
    </v-layout>
</template>

<script>
import BreadCrumbs from '@/components/BreadCrumbs'

export default {
    name: 'ParentLayout',
    components: {
        BreadCrumbs,
    },
    props: {
        title: {
            type: String,
            default: null,
        },
        subHeading: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
<style lang="scss" scoped>
.title-bar {
    display: flex;
    justify-content: flex-end;
    .justify-start {
        margin-right: auto;
    }
}
</style>
