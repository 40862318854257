<template>
    <parent-layout title="Configurations" sub-heading>
        <template #actions>
            <create-config-button v-if="hasClient && !fetchingClient" :client-id="clientId" />
        </template>
        <template v-if="fetchingClient">
            <v-skeleton-loader type="table" />
        </template>
        <template v-if="hasClient && !fetchingClient">
            <ag-grid-simple
                :key="client.id"
                :row-data="authentications"
                :column-defs="columnDefs"
                :fetching="fetchingAuth"
            >
            </ag-grid-simple>
        </template>
        <template v-if="!hasClient && !fetchingClient">
            No valid client associated with this district
        </template>
    </parent-layout>
</template>
<script>
import ColumnDef from '@/helpers/ag-grid/columnDef/imt/authentications'
import { mapActions, mapGetters } from 'vuex'
import AgGridSimple from '@/components/table/AgGridSimple'
import CreateConfigButton from '@/views/Imt/Authentications/CreateConfigButton'
import ParentLayout from '@/components/layout/ParentLayout.vue'

export default {
    name: 'DistrictAuthentication',
    components: {
        CreateConfigButton,
        AgGridSimple,
        ParentLayout,
    },
    data: () => ({
        columnDefs: ColumnDef,
        passwordAuth: null,
        client: null,
    }),
    computed: {
        ...mapGetters({
            clientId: 'getIlluminateClientId',
            authentications: 'authentication/getItems',
            fetchingAuth: 'authentication/getFetching',
            fetchingClient: 'client/getFetching',
            getAuthByField: 'authentication/getAuthByField',
        }),
        hasClient: function () {
            return this.client?.id
        },
    },
    async mounted() {
        this.client = await this.fetchClient(this.clientId)
        await this.fetchAuthentication({ clientId: this.clientId })
    },
    methods: {
        ...mapActions({
            fetchClient: 'client/get',
            fetchAuthentication: 'authentication/cget',
        }),
    },
}
</script>
<style lang="scss">
.auth-configuration {
    .v-input {
        &__slot {
            flex-direction: row-reverse;
        }
    }
}
</style>
