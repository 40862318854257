<template>
    <v-breadcrumbs v-if="items" class="pt-1 pb-1" large :items="items"></v-breadcrumbs>
</template>

<script>
export default {
    name: 'BreadCrumbs',
    computed: {
        items() {
            const breadcrumbs = this.$route.meta.breadcrumb
            if (!breadcrumbs) {
                return
            }
            const items = breadcrumbs.map((routeName) => {
                const route = this.$router.resolve({
                    name: routeName,
                    params: this.$route.params,
                })

                return {
                    to: route.href,
                    exact: true,
                    link: true,
                    disabled: false,
                    text: routeName,
                }
            })
            //add current route (disabled)
            items.push({
                href: this.$route.fullPath,
                disabled: true,
                text: this.$route.name,
            })

            return items
        },
    },
}
</script>